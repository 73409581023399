/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.background[data-v-4f36ef26] {
  min-height: 100%;
  background-color: #f3f3f3;
  position: relative;
}
.background.bottom-padding[data-v-4f36ef26] {
  padding-bottom: 10rem;
}
.background[data-v-4f36ef26]  .selection-holder {
  width: 40rem !important;
}
.first-setup-sentence[data-v-4f36ef26] {
  font-weight: normal;
  padding: 2rem;
}
.sub-section-title[data-v-4f36ef26] {
  font-size: 1.6rem;
}
.label-container-center[data-v-4f36ef26] {
  max-width: 100%;
  display: flex;
  align-items: center;
  padding: 2rem;
}
.label-container-center.bottom-padding[data-v-4f36ef26] {
  padding-bottom: 2rem;
}
.label-text[data-v-4f36ef26] {
  font-weight: bold;
  margin-right: 1rem;
  display: inline-block;
  width: 30rem;
}
.action-icon[data-v-4f36ef26] {
  font-size: 1.2rem;
  position: relative;
  top: -1rem;
  display: inline-block;
}
.no-additional-currencies-text[data-v-4f36ef26] {
  margin-right: 1rem;
  display: inline-block;
}
.input-box[data-v-4f36ef26] {
  flex: 1;
  padding: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1.4rem;
  font-family: inherit;
}
.single-option[data-v-4f36ef26] {
  width: 100%;
  background: #fff;
  opacity: 0.6;
}
.additional-currencies[data-v-4f36ef26] {
  flex: 1;
  font-size: 1.4rem;
  max-width: 50rem;
}
.radio-buttons[data-v-4f36ef26] {
  display: flex;
  flex: 1;
  align-items: center;
}
.radio-buttons input[data-v-4f36ef26] {
  display: none;
}
.radio-buttons label[data-v-4f36ef26] {
  display: inline-block;
  width: 12rem;
  padding: 1rem;
  text-align: center;
  background-color: #ccc;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 1rem;
  font-size: 1.4rem;
}
.radio-buttons input:checked + label[data-v-4f36ef26] {
  color: #fff;
  background-color: #e93f6f;
}
.radio-buttons input:disabled + label[data-v-4f36ef26] {
  opacity: 0.6;
  cursor: default;
}
.save-slide-out[data-v-4f36ef26] {
  width: 85%;
  position: fixed;
  transform: translateY(8rem);
  bottom: 1rem;
  transition: transform 0.3s ease-in;
  right: 0;
}
.save-slide-out.show-save[data-v-4f36ef26] {
  transform: translateY(0rem);
}
.save-slide-out .slide-out-inner[data-v-4f36ef26] {
  width: 36rem;
  margin: 0 auto;
  padding: 1.25rem;
  background: white;
  border-radius: 1rem 1rem 0 0;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.17);
}
.save-slide-out .slide-out-inner .btn[data-v-4f36ef26]:first-of-type {
  margin-right: 1rem;
}
.save-button-container[data-v-4f36ef26] {
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: #f3f3f3;
  box-shadow: rgba(0, 0, 0, 0.45) 0px -10px 20px -20px;
  width: 100%;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.save-button[data-v-4f36ef26] {
  width: 10%;
}
.invalid-input[data-v-4f36ef26] {
  border: 1px solid #f44336;
}
.warning-message[data-v-4f36ef26] {
  color: #f44336;
  margin-left: 2rem;
  padding-bottom: 1rem;
  font-size: 75%;
  font-weight: bold;
}
.info-message[data-v-4f36ef26] {
  margin-left: 2rem;
  padding-bottom: 1rem;
  font-size: 75%;
}