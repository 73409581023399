/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.actions[data-v-1e226eec] {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
table[data-v-1e226eec] {
  border: 1px solid #ccc;
  border-radius: 4px;
  border-collapse: collapse;
  width: 100%;
}
th[data-v-1e226eec] {
  background-color: #ddd;
  color: #424242;
  padding: 1.5rem;
}
th.w-20[data-v-1e226eec] {
  width: 20%;
}
th.w-40[data-v-1e226eec] {
  width: 40%;
}
tbody td[data-v-1e226eec] {
  padding: 0.5rem 1.5rem;
  vertical-align: baseline;
}
tbody tr[data-v-1e226eec] {
  position: relative;
}
tbody tr[data-v-1e226eec]:hover {
  background-color: #f0f0f0;
}
.currency-info-page[data-v-1e226eec] {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.currency-info-page h2[data-v-1e226eec] {
  color: #003061;
  margin-bottom: 1rem;
}
.currency-info-page button[data-v-1e226eec] {
  margin-top: 2rem;
}
.add-currency-container[data-v-1e226eec] {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}
.input-box[data-v-1e226eec] {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1.4rem;
  font-family: inherit;
}
.button-group[data-v-1e226eec] {
  display: flex;
  gap: 1rem;
}
.btn.btn--default[data-v-1e226eec]:disabled {
  opacity: 0.6;
  cursor: default;
}
.info-message[data-v-1e226eec] {
  margin-top: 1rem;
  font-size: 75%;
}